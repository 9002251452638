import Layout from "./components/Layout";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Deposit from "./pages/Deposit";
import Withdrawal from "./pages/Withdrawal";
import Checkouts from "./pages/Checkouts";
import Players from "./pages/Players";
import Sidebar from "./components/Sidebar";
import MyAfyaWallets from "./pages/MyAfyaWallets";
import PrimaryWallet from "./pages/PrimaryWallets";
import BeneficiaryWallet from "./pages/BeneficiaryWallet";
import BenWallet from "./pages/BenWallet";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route element={<Sidebar />}>
          <Route path="/primary/wallets" element={<Players />} />
          <Route path="/beneficiary/wallets" element={<BeneficiaryWallet />} />
          <Route path="/priwallet/:id" element={<PrimaryWallet />} />
          <Route path="/benwallet/:id" element={<BenWallet />} />
          <Route path="/deposits" element={<Deposit />} />
          <Route path="/my-afya" element={<Withdrawal />} />
          <Route path="/checkouts" element={<Checkouts />} />
          <Route path="/myafyawallet/:id" element={<MyAfyaWallets />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
