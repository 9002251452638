import React from "react";
import { SyncOutlined } from "@ant-design/icons";
import { Input, Table } from "antd";
const { Search } = Input;

const columns = [
  {
    title: "Msisdn",
    dataIndex: "phone",
    key: "phone",
  },

  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },

  {
    title: "Result code",
    dataIndex: "result",
    key: "result",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Notes",
    dataIndex: "notes",
    key: "notes",
    render: (text) => <p>{text}</p>,
  },
  {
    title: "Initiated at",
    dataIndex: "time",
    key: "time",
    render: (text) => <p>{text}</p>,
  },
];
const onSearch = (value) => console.log(value);
function Checkouts() {
  const data = [
    {
      key: "1",
      amount: 3220,
      phone: "254712345678",
      result: "1037",
      time: "Tue-27-6-2023 14:34",
      notes: "DS timeout user cannot be reached",
    },
    {
      key: "2",
      amount: 2000,
      phone: "254712345678",
      result: "1032",
      time: "Tue-27-6-2023 10:34",
      notes: "Request cancelled by user",
    },
    {
      key: "3",
      amount: 400,
      phone: "254712345678",
      time: "Wed-28-6-2023 18:03",
      result: "17",
      notes: "System internal error",
    },
    {
      key: "4",
      amount: 4000,
      phone: "254712345678",
      time: "Wed-28-6-2023 22:13",
      result: "1032",
      notes: "Request cancelled by user",
    },
    {
      key: "5",
      amount: 950,
      phone: "254712345678",
      time: "Thu-29-6-2023 08:03",
      result: "1032",
      notes: "Request cancelled by user",
    },
  ];
  return (
    <div className=" p-4">
      <div className="">
        <h4 className="border-b border-gray-200 bg-transparent text-gray-600 font-[700] py-2 mx-auto lg:mx-7 rounded text-left">
          Checkouts
        </h4>
      </div>
      <div>
        <div className=" flex justify-between items-center mx-10 border-b border-gray-300 pb-5 my-6">
          <div className="flex space-x-3">
            <div className="flex border border-black/40 rounded justify-center items-center p-2 w-10">
              <SyncOutlined spin />
            </div>
            <div className="border border-black/40 rounded cursor-pointer text-[14] font-[500] hover:border-blue-500/50 hover:text-blue-800 flex justify-center items-center p-1 w-28">
              <p>Export</p>
            </div>
          </div>

          <Search
            allowClear
            placeholder="search"
            onSearch={onSearch}
            style={{
              width: 250,
            }}
            loading={false}
          />
        </div>
      </div>
      <div className="">
        <Table columns={columns} dataSource={data} />
      </div>
    </div>
  );
}

export default Checkouts;
