import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import axios from "axios";
import _ from "lodash";
import {
  getDeposits,
  getTotalDeposits,
  getWithdrawals,
  totalUsers,
  users,
} from "../Urls";
import { message } from "antd";
import { Bars } from "react-loader-spinner";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState(0);
  const [deposits, setDeposits] = useState(0);
  const [filter, setFilter] = useState(0);
  const [newUsers, setNewUsers] = useState([]);
  const [total, setTotal] = useState([]);
  const [totalWithdrawals, setTotalWithdrawals] = useState(0);

  useEffect(() => {
    getUsersdata();
  }, []);
  // Fetch user stats
  const getUsersdata = async () => {
    try {
      setLoading(true);
      const res = await axios.get(users);
      if (res.data.success) {
        setAllUsers(res.data.total_users);
        setNewUsers(res.data.users);
      }
      const res2 = await axios.get(getDeposits);
      if (res2.data.success) {
        const oldDep = parseInt(res2.data.total);
        const depv2 = await axios.get(getTotalDeposits);
        // if (depv2.data.success) {
        const newDeps = parseInt(depv2.data.totalDeposits);
        // }
        setDeposits(oldDep + newDeps);
      }
      const res3 = await axios.get(getWithdrawals);
      if (res3.data.success) {
        setTotalWithdrawals(parseInt(res3.data.total_withdrawals));
        console.log(res);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong. Connection problem");
    }
  };
  useEffect(() => {
    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 7);

    const sortedData = _.orderBy(newUsers, ["created_at"], ["desc"]);
    const filteredItems = _.filter(newUsers, (item) => {
      const itemDate = new Date(item.created_at);
      return itemDate >= sevenDaysAgo && itemDate <= currentDate;
    });

    setNewUsers(filteredItems.length);
  }, [newUsers]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Performance",
      },
    },
  };
  const optionsGrowth = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Users Visualization",
      },
    },
  };

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const data = {
    labels,
    datasets: [
      {
        label: "Deposits",
        data: [
          30200, 50000, 70000, 90200, 90000, 40342, 63033, 80000, 30000, 90000,
          120000, 120000,
        ],
        backgroundColor: "rgba(5, 114, 20, 0.8)",
      },
      {
        label: "Withdrawals",
        data: [
          20450, 65000, 50800, 79910, 50000, 20000, 70900, 70000, 20500, 30700,
          80400, 99000,
        ],
        backgroundColor: "rgba(97, 41, 144, 0.9)",
      },
    ],
  };
  const usersData = {
    labels,
    datasets: [
      {
        label: "Users",
        data: [
          10, 100, 700, 1200, 2000, 7342, 8033, 10000, 17000, 20000, 42800,
          90000,
        ],
        backgroundColor: "rgba(255, 99, 132, 0.9)",
      },
      {
        label: "New Users",
        data: [
          10, 90, 600, 500, 800, 5342, 691, 1967, 7000, 3000, 22800, 47200,
        ],
        backgroundColor: "rgba(53, 162, 235, 0.9)",
      },
    ],
  };

  return (
    <>
      {loading ? (
        <div className="min-h-screen flex flex-col justify-center items-center bg-[#02132E]/20">
          <Bars
            height="80"
            width="70"
            color="#02132E"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <p className="text-[#02132E] text-[18px] font-[500] my-5">
            Loading...
          </p>
        </div>
      ) : (
        <div className="bg-[#02132E] text-gray-200">
          <div className="bg-white">
            <h4 className="border-b border-gray-200 text-gray-600 font-[700] py-2 mx-auto lg:mx-7 rounded text-left">
              Summary
            </h4>
          </div>

          <div className="bg-white p-4 mb-4 grid lg:grid-cols-4 sm:grid-cols-2 text-gray-700 gap-4">
            {/* Today's money */}
            <div className="bg-white shadow-xl rounded-xl border flex justify-between items-center p-4 border-gray-200">
              <div className="flex flex-col">
                <p className="text-gray-500 text-[12px] font-bold">
                  Today's Deposits
                </p>
                <p className="text-[14px] font-[700]">
                  KES {deposits.toLocaleString()}
                  {/* <span className="text-green-600 text-[12px] font-bold">+10%</span> */}
                </p>
              </div>
              <div className="bg-pink-700 p-5 rounded-md shadow-xl"></div>
            </div>

            {/* Today's users */}
            <div className="bg-white shadow-xl rounded-xl border flex justify-between items-center p-4 border-gray-200">
              <div className="flex flex-col">
                <p className="text-gray-500 text-[12px] font-bold">
                  Total Players
                </p>
                <p className="text-[14px] font-[700]">
                  {allUsers.toLocaleString()}
                  {/* <span className="text-green-600 text-[12px] font-bold">+3%</span> */}
                </p>
              </div>
              <div className="bg-amber-700 p-5 rounded-md shadow-xl"></div>
            </div>

            {/* New Users */}

            <div className="bg-white shadow-xl rounded-xl border flex justify-between items-center p-4 border-gray-200">
              <div className="flex flex-col">
                <p className="text-gray-500 text-[12px] font-bold">
                  New Players
                </p>
                <p className="text-[14px] font-[700]">
                  +{total}
                  {/* <span className="text-pink-600 text-[12px] font-bold">-10%</span> */}
                </p>
              </div>
              <div className="bg-pink-700 p-5 rounded-md shadow-xl"></div>
            </div>
            {/* Winners */}

            <div className="bg-white shadow-xl rounded-xl border flex justify-between items-center p-4 border-gray-200">
              <div className="flex flex-col">
                <p className="text-gray-500 text-[12px] font-bold">
                  Today's Winners
                </p>
                <p>
                  230
                  <span className="text-green-600 text-[12px] font-bold">
                    +2.1%
                  </span>
                </p>
              </div>
              <div className="bg-green-700 p-5 rounded-md shadow-xl"></div>
            </div>

            {/* Current deposits */}
            <div className="bg-white shadow-xl rounded-xl border flex justify-between items-center p-4 border-gray-200">
              <div className="flex flex-col">
                <p className="text-gray-500 text-[12px] font-bold">
                  Total Deposits
                </p>
                <p className="text-[14px] font-[700]">
                  KES {deposits.toLocaleString()}
                  {/* <span className="text-green-600 text-[12px] font-bold">
                +12.7%
              </span> */}
                </p>
              </div>
              <div className="bg-blue-700 p-5 rounded-md shadow-xl"></div>
            </div>

            {/* Availble balance */}
            <div className="bg-white shadow-xl rounded-xl border flex justify-between items-center p-4 border-gray-200">
              <div className="flex flex-col">
                <p className="text-gray-500 text-[12px] font-bold">
                  Total Withdrawals
                </p>
                <p className="text-[15px] font-[700] ">
                  KES {totalWithdrawals.toLocaleString()}
                  <span className="text-green-600 text-[12px] mx-5 font-bold">
                    ---
                  </span>
                </p>
              </div>
              <div className="bg-pink-900 p-5 rounded-md shadow-xl"></div>
            </div>
            {/* General stats */}
            <div className="bg-white shadow-xl sm:col-span-2 rounded-xl border flex justify-between items-center p-4 border-gray-200">
              <div className="flex w-full justify-between ml-3 sm:ml-10 flex-col">
                <p className="text-gray-500 text-[12px] mb-1 font-bold">
                  General Stats
                </p>

                <div className="flex ">
                  {/* Users */}
                  <div className="w-full">
                    <div className="flex  space-x-2">
                      <div className="bg-pink-700 p-2 lg:h-6 lg:w-6 h-2 w-2 rounded-md shadow-xl"></div>
                      <p className="text-sm font-bold">Players</p>
                    </div>
                    <div>
                      <p className="text-[14px] mt-2 font-bold">{allUsers}</p>
                    </div>
                  </div>

                  <div className="w-full">
                    <div className="flex  space-x-2">
                      <div className="bg-blue-700 p-2 h-2 w-2 lg:h-6 lg:w-6 rounded-md shadow-xl"></div>
                      <p className="text-sm font-bold">Wins</p>
                    </div>
                    <div>
                      <p className="text-[14px] mt-2 font-bold">10K</p>
                    </div>
                  </div>

                  <div className="w-full">
                    <div className="flex space-x-2">
                      <div className="bg-green-700 p-2 lg:h-6 lg:w-6 h-2 w-2 rounded-md shadow-xl"></div>
                      <p className="text-sm font-bold">Revenue</p>
                    </div>
                    <div>
                      <p className="text-[14px] mt-2 font-bold">KES 1500</p>
                    </div>
                  </div>

                  <div className="w-full">
                    <div className="flex space-x-2">
                      <div className="bg-amber-700 p-2 h-2 w-2 lg:h-6 lg:w-6 rounded-md shadow-xl"></div>
                      <p className="text-sm font-bold">Active</p>
                    </div>
                    <div>
                      <p className="text-[14px] mt-2 font-bold">{allUsers}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Graph demos */}
          <div className="bg-white grid lg:grid-cols-2 gap-4 p-4">
            <div className="p-2 shadow-lg rounded-lg border border-[#02132E]/50">
              <div>
                <Bar options={options} data={data} />
              </div>
            </div>
            <div className="p-2 shadow-lg rounded-lg border border-[#02132E]/50">
              <div>
                <Line options={optionsGrowth} data={usersData} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Dashboard;
