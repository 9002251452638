const BASE_URL = `https://bitsbet.net/bitsbet-api/public/api`;

// get user stats api calls
export const users = `${BASE_URL}/users`;
export const totalUsers = `${BASE_URL}/totalusers`;
export const newUsers = `${BASE_URL}/newusers`;

// Get deposits call
export const getDeposits = `${BASE_URL}/deposits`;
export const getTotalDeposits = `${BASE_URL}/fetch/depositsv2`;
export const getWithdrawals = `${BASE_URL}/withdrawals`;

// Validate transaction
export const depositStatus = `${BASE_URL}/deposit/status`;
