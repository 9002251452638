import React, { useEffect, useState } from "react";
import { SyncOutlined } from "@ant-design/icons";
import { Table, message } from "antd";
import { getWithdrawals } from "../Urls";
import _ from "lodash";
import axios from "axios";
import { BiSearchAlt } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";

function BenWallet() {
  const [withdrawList, setWithrawList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchParam, setSearchParam] = useState("");

  let navigate = useNavigate();
  let { id } = useParams();

  const columns = [
    {
      title: "Serial No",
      dataIndex: "id",
      key: "id",
      // render: (id) => <a>{id}</a>,
    },

    {
      title: "Wallet ID",
      dataIndex: "wallet_id",
      key: "wallet_id",
      render: (text) => <p className="">{text}</p>,
    },

    {
      title: "User ID",
      dataIndex: "owner_id",
      key: "owner_id",

      render: (text) => <p className="">{text}</p>,
    },
    {
      title: "Wallet Type",
      dataIndex: "wallet_type",
      key: "wallet_type",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Wallet Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text) => <p>KES {text} .00</p>,
    },
    {
      title: "Wallet Created On",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <p className="cursor-pointer">
          {new Date(text).toDateString()}-{new Date(text).toLocaleTimeString()}
        </p>
      ),
    },
  ];

  const withdrawals = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `https://imanihealth.co.ke/imani-health-api/public/api/get/ben/wallets/${id}`
      );
      if (res.data.success) {
        // console.log(res.data.data);
        setWithrawList(res.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong. Connection problem");
    }
  };
  function getDisplayedData() {
    if (searchParam === "") {
      return withdrawList || [];
    } else {
      return (
        withdrawList.filter((withdraw) =>
          withdraw.id_no.includes(searchParam)
        ) || []
      );
    }
  }

  useEffect(() => {
    withdrawals();
  }, []);

  return (
    <div className=" p-4">
      <div className="">
        <h4 className="border-b border-gray-200 bg-transparent text-gray-600 font-[700] py-2 mx-auto lg:mx-7 rounded text-left">
          Beneficiary Wallet for National Id No: {id}
        </h4>
        <p className="text-[0.8rem] ml-[2rem] text-orange-400">
          Click user's first name to view Beneficiary wallets tied to the user
          accounts
        </p>
      </div>
      <div>
        <div className=" flex justify-between items-center mx-10 border-b border-gray-300 pb-5 my-6">
          <div className="flex space-x-3">
            <div
              className="flex border cursor-pointer border-black/40 rounded justify-center items-center p-2 w-10"
              onClick={withdrawals}
            >
              <SyncOutlined spin={loading} />
            </div>
            <div className="border border-black/40 rounded cursor-pointer text-[14] font-[500] hover:border-blue-500/50 hover:text-blue-800 flex justify-center items-center p-1 w-28">
              <p>Export</p>
            </div>
          </div>

          <div className="py-1 px-2 w-[250px] text-gray-400 border border-gray-300 rounded flex justify-between items-center">
            <BiSearchAlt />
            <input
              type="text"
              name="search"
              value={searchParam}
              placeholder="Search by ID Number"
              onChange={(e) =>
                setSearchParam(
                  e.target.value.replace(/[- ?'" = A-Z ` #*;,N.<>/]/gi, ""),
                  e.target.value
                )
              }
              // onChange={(e) => setSearchParam(e.target.value)}
              className="outline-none w-full mx-1"
            />
          </div>
        </div>
      </div>
      <div className="">
        {loading ? (
          <div className="bg-white flex justify-center items-center h-[10rem]">
            <SyncOutlined
              spin={loading}
              className="text-[2.5rem] text-gray-600 font-bold"
            />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={getDisplayedData()}
            pagination={{ showSizeChanger: true }}
          />
        )}
      </div>
    </div>
  );
}

export default BenWallet;
