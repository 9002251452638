import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Spin,
  message,
  notification,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { LockOutlined, PhoneOutlined } from "@ant-design/icons";

const Login = () => {
  const navigate = useNavigate();
  const signIn = async (values) => {
    console.log(values);
    if (
      values.email != "anne@imanihealth.co.ke" &&
      values.password != "vfkgMhec#88t%E(J"
    ) {
      openNotificationWithIcon("error");
      return;
    } else {
      navigate("/primary/wallets");
    }
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "Wrong Login Credentials",
      description: "You are probably using wrong login credentials.",
    });
  };
  return (
    <div className="bg-white p-4 lg:p-10">
      <div>
        {contextHolder}
        <Form
          name="admin_login"
          className="w-11/12 sm:w-1/2 md:w-4/12 mx-auto border border-[#02132E]/30 shadow-xl shadow-[#02132E]/50 my-5 rounded p-8 bg-[#d3dbe7]"
          scrollToFirstError
          initialValues={{
            remember: true,
          }}
          onFinish={signIn}
        >
          <p className="text-[18px] font-[500] text-gray-700 m-4 text-center">
            ImaniHealth<span className="text-amber-700"></span> Admin Login
          </p>
          <Form.Item name="phone" hasFeedback>
            <Input
              prefix={<PhoneOutlined />}
              placeholder="Phone number"
              className="h-10"
            />
          </Form.Item>
          <Form.Item
            name="password"
            hasFeedback
            rules={[
              {
                min: 4,
                message: "Password too short",
              },

              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              placeholder="Password"
              className="h-10"
            />
          </Form.Item>
          <Form.Item className="flex space-x-8 w-full">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              className="text-gray-200 hover:text-white w-full bg-blue-950 hover:bg-gray-700 mr-4"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="absolute bottom-0 py-2 px-2 w-[90%] text-center text-xs text-gray-700">
        <div>
          <p>
            imanihealth.co.ke &copy;{new Date().getFullYear()} All rights
            reserved
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
