import React, { useEffect, useState } from "react";
import { SyncOutlined } from "@ant-design/icons";
import { Table, message } from "antd";
import axios from "axios";
import { depositStatus, getTotalDeposits } from "../Urls";
import { BiSearchAlt } from "react-icons/bi";
import _ from "lodash";
const formattedNumber = (text) => (
  <p>
    {parseFloat(text).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}
  </p>
);

const columns = [
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    key: "Amount",
    render: (text) => {
      {
        // const formattedNumber = (text) => (
        //   <p>
        //     {parseFloat(text).toLocaleString(undefined, {
        //       minimumFractionDigits: 2,
        //       maximumFractionDigits: 2,
        //     })}
        //   </p>
        // );
        return <p>{formattedNumber(text)}</p>;
      }
      // <p>{parseFloat(text).toFixed(2)}</p>
    },
  },

  {
    title: "Status",
    dataIndex: "ResultCode",
    key: "ResultCode",
    render: (text) => (
      <p>
        {text === null ? (
          <p className="w-fit bg-amber-700 p-2 font-[400] text-[12px] text-gray-200 rounded shadow">
            Unprocessed
          </p>
        ) : (
          <p className="w-fit bg-green-700 px-4 py-2 font-[400] text-[12px] text-gray-200 rounded shadow">
            Processed
          </p>
        )}
      </p>
    ),
  },
  {
    title: "Transaction Time",
    dataIndex: "created_at",
    key: "created_at",
    render: (text) => {
      const initialT = new Date(text);
      const newT = new Date(initialT.getTime() - 3 * 60 * 60 * 1000);
      return (
        <p>
          {newT.toDateString()} - {newT.toLocaleTimeString()}
        </p>
      );
    },
  },
  {
    title: "Notes",
    dataIndex: "ResultDesc",
    key: "ResultDesc",
    render: (text) => (
      <p>{text === null ? <p>Awaiting processing</p> : <p>{text}</p>}</p>
    ),
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <p>
        {record.ResultCode === null ? (
          <p
            onClick={async () => {
              const checkoutrequestid = record.CheckoutRequestID;
              // const resultCode = record.ResultCode;
              // const resultDesc = record.ResultDesc;
              console.log(`${checkoutrequestid}  `);
              message.loading("Reconciling transaction");
              const stat = await axios.post(depositStatus, {
                checkoutrequestid,
              });

              const resultCode = stat.data.ResultCode;
              const resultDesc = stat.data.ResultDesc;
              console.log(resultCode);
              const res = await axios.post(
                "https://bitsbet.net/bitsbet-api/public/api/save/depositsv2",
                {
                  checkoutrequestid,
                  resultCode,
                  resultDesc,
                }
              );
              console.log(res.data);
              if (res.data.data === "transaction processed successfully") {
                message.info("Validation Successful");
                window.location.reload(false);
              } else if (
                res.data.data === "transaction failed but saved on db"
              ) {
                message.info("Transaction processed, user did not respond");
                window.location.reload(false);
              } else {
                message.error("Transaction already processed");
              }
            }}
            className="w-fit cursor-pointer hover:bg-green-700/50 active:bg-green-700/80 bg-green-700 px-4 py-2 font-[400] text-[12px] text-gray-200 rounded shadow"
          >
            Reconcile
          </p>
        ) : (
          <p>Completed</p>
        )}
      </p>
    ),
  },
];

function Deposit() {
  const [deposits, setDeposits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  // const [searchLoad, setSearchLoad] = useState(false);

  const getDepositedCash = async () => {
    try {
      setLoading(true);
      const res = await axios.get(getTotalDeposits);
      if (res.data.success) {
        const deps = res.data.data;
        setDeposits(_.orderBy(deps, ["created_at"], ["desc"]));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong. Connection problem");
    }
  };

  function getDisplayedData() {
    if (searchParam === "") {
      return deposits || [];
    } else {
      return (
        deposits.filter((deposit) => deposit.phone.includes(searchParam)) || []
      );
    }
  }

  useEffect(() => {
    getDepositedCash();
  }, []);

  return (
    <div className=" p-4">
      <div className="">
        <h4 className="border-b border-gray-200 bg-transparent text-gray-600 font-[700] py-2 mx-auto lg:mx-7 rounded text-left">
          Deposits
        </h4>
      </div>
      <div>
        <div className=" flex justify-between items-center mx-10 border-b border-gray-300 pb-5 my-6">
          <div
            className="flex border cursor-pointer border-black/40 rounded justify-center items-center p-2 w-10"
            onClick={getDepositedCash}
          >
            <SyncOutlined spin={loading} />
          </div>

          <div className="py-1 px-2 w-[250px] text-gray-400 border border-gray-300 rounded flex justify-between items-center">
            <BiSearchAlt />
            <input
              type="text"
              name="search"
              value={searchParam}
              placeholder="Search by phone"
              onChange={(e) =>
                setSearchParam(
                  e.target.value.replace(/[- ?'" = A-Z ` #*;,N.<>/]/gi, ""),
                  e.target.value
                )
              }
              // onChange={(e) => setSearchParam(e.target.value)}
              className="outline-none w-full mx-1"
            />
          </div>
        </div>
      </div>
      <div className="">
        {loading ? (
          <div className="bg-white flex justify-center items-center h-[10rem]">
            <SyncOutlined
              spin={loading}
              className="text-[2.5rem] text-gray-600 font-bold"
            />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={getDisplayedData()}
            pagination={{ showSizeChanger: true }}
          />
        )}
      </div>
    </div>
  );
}

export default Deposit;
