import React from "react";

const Navbar = () => {
  return (
    <nav className="bg-white border-gray-200 px-2 py-2.5 dark:bg-[#02132E]">
      <div className="container flex justify-between items-center mx-auto pt-3">
        <div className="flex items-center mx-auto">
          <span className="text-[18px] font-[300] whitespace-nowrap dark:text-white">
            Welcome Anne,
          </span>
        </div>

        <div className="flex justify-end pr-4"></div>
      </div>
    </nav>
  );
};

export default Navbar;
